/* Footer.css */
.footer-container {
    padding: 20px 0;
    background-color: #f0f0f0;
    text-align: center;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .footer-text {
    color: #333;
    font-size: 14px;
  }